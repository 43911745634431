import React from 'react';
import LinkGenerator from './LinkGenerator';

function App() {
  return (
    <div className="App">
      <h1>Social Link Generator</h1>
      <LinkGenerator />
    </div>
  );
}

export default App;
