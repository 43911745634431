import React, { useState } from 'react';

const LinkGenerator = () => {
    const [slug, setSlug] = useState('');
    const [text, setText] = useState('');
    const [showScript, setShowScript] = useState(false);
    const [combinedScript, setCombinedScript] = useState('');

    const generateLinks = (event) => {
        event.preventDefault();
        let url = `https://ddosify.com/blog/${slug}`;
        
        // if there is not trailing slash, add it
        if (url[url.length - 1] !== '/') {
            url += '/';
        }

        // Scripts with unencoded URL and text
        const twitterScript = `<a href="https://twitter.com/intent/tweet?url=${url}&text=${text}&via=ddosify" style={{marginRight:15, color:'white'}}><Icon icon="line-md:twitter-x-alt" width="36" height="36" /></a>`;
        const linkedinScript = `<a href="https://www.linkedin.com/shareArticle?mini=true&url=${url}&source=Linkedin" style={{marginRight:15, color:'white'}}><Icon icon="devicon-plain:linkedin" width="36" height="36" /></a>`;

        const newCombinedScript = `${twitterScript}\n\n${linkedinScript}`;
        setCombinedScript(newCombinedScript);
        setShowScript(true);
    };

    return (
        <div>
            <form onSubmit={generateLinks} style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%' }}>
                <input
                    type="text"
                    placeholder="Slug"
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                    required
                    style={{ margin: '10px', padding: '10px', width: '300px' }}
                />
                <input
                    type="text"
                    placeholder="Text"
                    value={text}
                    required
                    onChange={(e) => setText(e.target.value)}
                    style={{ margin: '10px', padding: '10px', width: '300px' }}
                />
                <button type="submit" style={{ margin: '10px', padding: '10px', width: '320px' }}>Generate Links</button>
            </form>

            {showScript && (
                <div>
                    <p></p>
                    <textarea
                        readOnly
                        value={combinedScript}
                        style={{ width: '100%', height: '150px' }}
                    />
                </div>
            )}
        </div>
    );
};

export default LinkGenerator;
